import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Content from '../components/Content'

// Export Template for use in CMS preview
export const ThankYouPageTemplate = ({
  title,
  subtitle,
  primaryColor,
  buttonContent,
  destinationUrl

}) => (
  <main className="ThankYouPage">
    <section className="section taCenter" style={{backgroundColor:"white"}}>
      <div className="container">
        <h1><Content source={title} /></h1>
        <Content source={subtitle} className="taLeft"/>
        <a href={destinationUrl}>
          <button
            className='Button Form--SubmitButton'
            type='submit'
            value='redirected'
            style={{background: primaryColor, border: "2px solid white", alignSelf: "center"}}
            id="button"
          >{buttonContent}
          </button>
        </a>
      </div>
    </section>
  </main>
)

const ThankYouPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
    navVisible={false}
  >
    <ThankYouPageTemplate {...page.frontmatter} body={page.html} />
  </Layout>
)
export default ThankYouPage

export const pageQuery = graphql`
  query ThankYouPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        primaryColor
        buttonContent
        destinationUrl
      }
    }
  }
`
